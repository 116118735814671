/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { ColumnCover, Column, Menu, ColumnWrapper, Subtitle, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, ColumnWrap, TitleMain, Text, Title, Image, Button } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <ColumnCover name={"menu"}>
          
          <Column className="--menu --style4 pb--30 pl--40 pr--40 pt--30" menu={true}>
            
            <Menu className="--justify --full mt--0 pb--60 pl--06 pr--40 pt--06 flex--center" style={{"maxWidth":""}} fullscreen={true}>
              
              <ColumnWrapper className="menu-logo-box" style={{"maxWidth":625}}>
                
                <Subtitle className="subtitle-box subtitle-box--shadow2 subtitle-box--right fs--72 subtitle-box--invert" style={{"maxWidth":390}} content={"GEAR technik s.r.o.<br>"}>
                </Subtitle>

              </ColumnWrapper>

              <MenuWrap >
                
                <MenuButton >
                </MenuButton>

                <MenuWrapBox >
                  
                  <MenuButtonClose >
                  </MenuButtonClose>

                </MenuWrapBox>

              </MenuWrap>

            </Menu>

          </Column>

        </ColumnCover>


        <Column className="css-jko48u bg--left bg--center --full" name={"sluzby"} parallax={false} fullscreen={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/17189/ffecbcfbc16d4017ab5637b87dc4782c_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17189/ffecbcfbc16d4017ab5637b87dc4782c_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/17189/ffecbcfbc16d4017ab5637b87dc4782c_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17189/ffecbcfbc16d4017ab5637b87dc4782c_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/17189/ffecbcfbc16d4017ab5637b87dc4782c_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/17189/ffecbcfbc16d4017ab5637b87dc4782c_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17189/ffecbcfbc16d4017ab5637b87dc4782c_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17189/ffecbcfbc16d4017ab5637b87dc4782c_s=3000x_.jpg);
    }
  
    `}>
        </Column>


        <Column className="pb--40 pt--50" name={"sluzby-2"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--1 pb--30 pt--30" anim={"2"} animS={"5"} style={{"maxWidth":1080}} columns={"1"}>
            
            <ColumnWrapper className="--center" style={{"maxWidth":900}}>
              
              <TitleMain className="title-box" style={{"maxWidth":874}} content={"Průmyslové převodovky opravujeme, servisujeme&nbsp; a navrhujeme&nbsp; již&nbsp; 20 let.<br>"}>
              </TitleMain>

              <Text className="text-box" style={{"maxWidth":""}} content={"Opravy a repase průmyslových převodovek, návrh nových, dodávka náhradních dílů, servis průmyslových převodovek to je naše práce a radost. Nabízíme dlouholetou zkušenost, spolehlivost a kvalitu odvedené práce.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left" name={"sluzby-3"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 el--3" anim={"2"} animS={"5"} style={{"maxWidth":""}} columns={"3"}>
            
            <ColumnWrapper className="--center mb--30" style={{"maxWidth":900}}>
              
              <Subtitle className="subtitle-box fs--36" style={{"maxWidth":""}} content={"Opravy původních průmyslových převodovek<br>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"Z poškozených a opotřebených průmyslových převodovek dokážeme strojně opravit těleso, včetně vývrtů pro ložiska, navrhneme nové soukolí do osových vzdálenosti tělesa, dodat soukolí v kvalitě - cementované , kalené, broušené na DIN 6, včetně kvality povrchu Ra 0,8. Zajistíme ložiska od renomovaných výrobců a odborné montáži ložisek na jednotlivé osy.&nbsp; Ustavení jednotlivých soukolí na měkkou barvu a vymezení všech vůlí je prováděno v nejvyšší kvalitě a přesnosti. Závěrečné vyzkoušení běhu převodovky je prováděno bez zatížení na jmenovité otáčky.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center mb--30" style={{"maxWidth":900}}>
              
              <Subtitle className="subtitle-box fs--36" style={{"maxWidth":""}} content={"Návrh nových průmyslových převodovek <br>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"Návrhy nových průmyslových převodovek zajišťujeme ve 3D programu, k návrhu postačí zástavbové rozměry původní převodovky, navrhneme kvalitu soukolí, nadimezujeme ložiska a vypočítame jejich životnost. Svařence těles kontrolujeme na olejotěsnost. Následná kompletní montáž probíhá v čistém, bezprašném prostředí tak, aby byla zajištěna ta nejvyšší kvalita celého výrobku.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center mb--30" style={{"maxWidth":900}}>
              
              <Subtitle className="subtitle-box fs--36" style={{"maxWidth":""}} content={"Servis průmyslových převodovek<br>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"Zajišťujeme servis průmyslových převodovek u zákazníka. Disponujeme kompletním servisním vybavení, od mobilní indukční soupravy na ohřev ložisek, po stahováky ložisek a hydraulické panenky.<br>Ceník včetně servisních sazeb zašleme na vyžádání.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center mb--30" style={{"maxWidth":900}}>
              
              <Subtitle className="subtitle-box fs--36" style={{"maxWidth":""}} content={"Vibrodiagnostika a termodiagnostika<br>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"Pro rychlý přehled o stavu stroje máme k dispozici vibrační techniku, která dokáže rozpoznat stav jednotlivých dílů případné jejich poškození. To vše za chodu stroje bez nutnosti jakékoliv demontáže. Ve spojení s termodiagnostikou lze určit teplotní rozdíly jednotlivých částí strojů.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center mb--30" style={{"maxWidth":900}}>
              
              <Subtitle className="subtitle-box fs--36 w--600 lh--12" style={{"maxWidth":""}} content={"<b>Výměny olejů<br></b>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"Provádíme výměnu olejů u zákazníku, čerpací technikou, doporučujeme druhy olejů včetně jejich specifikace. Zajišťujeme též rozbory olejů, ze stávajích náplní.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center mb--30" style={{"maxWidth":900}}>
              
              <Subtitle className="subtitle-box fs--36" style={{"maxWidth":""}} content={"Ustavování soustrojí<br>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"Pro zákazníky je možnost servisní ustavování soustrojí do osy v přesností dle jmenovitých otáček. Vlastníme přístroj od SKF a s nerezovými podložkami jsme schopni ustavit motor, převodovku do osy v rámci desetin milimetru.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"rlgq0i7yif"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--36" content={"Náhradní díly<br>"}>
              </Title>

              <Text className="text-box" content={"Počet zubů, výška zubu, osová vzdálenost, výkon, otáčky, tak málo stačí k návrhu náhradního dílu, tvorba ve 3D je samozřejmostí, včetně všech výpočtů. <br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box fs--36 w--600" content={"Řada TS, TSA, UCG<br>"}>
              </Title>

              <Text className="text-box" content={"Naše firma se specializuje na repase případně na náhrady typizovaných řad převodovek TS, TSA, UCG všech velikostí. Máme dlouholeté zkušenosti s opravami těchto převodovek a jejich náhradami ."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box fs--36" content={"Náhradní díly (stroje)<br>"}>
              </Title>

              <Text className="text-box" content={"Jsme schopni zajistit nejen rotační díly, ale i různé strojní součásti, díky možnosti 3D programu zajišťujeme i výrobu ostatních strojních součástí, krytů, držáků, kompletních svarků, spojek a brzdových systémů.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1u6qowf js-anim  --anim3 bg--center-h bg--center pb--60 pt--60" anim={"3"} name={"fcut3c0vkj4"} style={{"marginBottom":44}} parallax={false} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/17189/18b0b649be7b4c44bfc76cdbfda27ebf_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17189/18b0b649be7b4c44bfc76cdbfda27ebf_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/17189/18b0b649be7b4c44bfc76cdbfda27ebf_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17189/18b0b649be7b4c44bfc76cdbfda27ebf_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/17189/18b0b649be7b4c44bfc76cdbfda27ebf_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/17189/18b0b649be7b4c44bfc76cdbfda27ebf_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17189/18b0b649be7b4c44bfc76cdbfda27ebf_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17189/18b0b649be7b4c44bfc76cdbfda27ebf_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--2 --full flex--stretch" columns={"2"} fullscreen={true}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--right fs--43 title-box--invert" style={{"maxWidth":731}} content={"Aktuálně"}>
              </Title>

              <Text className="text-box w--900 text-box--invert" content={"<font color=\"rgba(134, 68, 53, 1)\">Nyní nabízíme repasovanou převodovou kříň typu TSA 031 370 - 03-6, s technickými údaji: P=14,5 kW, i=25, n1=1500 min-1, m=164 kg.<br>Převodová skříň je je po kompletní výměně převodů, ložisek, těsnění. Původní těleso bez vad, převodovka je odzkoušená. Vrchní nátěr RAL 5001<font color=\"rgba(134, 68, 53, 1)\"><i>-</i></font>odstín modrá.<br></font>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1vccfhm bg--center-h bg--center" name={"sf1sryv6cgb"} style={{"paddingTop":27,"marginBottom":1,"paddingBottom":198.5916748046875}} border={null} parallax={false} css={css`
      
    background-image: linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url(https://cdn.swbpg.com/t/17189/34ecee5957744ba88efb169dbd9259ca_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url(https://cdn.swbpg.com/t/17189/34ecee5957744ba88efb169dbd9259ca_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url(https://cdn.swbpg.com/t/17189/34ecee5957744ba88efb169dbd9259ca_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url(https://cdn.swbpg.com/t/17189/34ecee5957744ba88efb169dbd9259ca_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url(https://cdn.swbpg.com/t/17189/34ecee5957744ba88efb169dbd9259ca_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url(https://cdn.swbpg.com/t/17189/34ecee5957744ba88efb169dbd9259ca_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url(https://cdn.swbpg.com/t/17189/34ecee5957744ba88efb169dbd9259ca_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url(https://cdn.swbpg.com/t/17189/34ecee5957744ba88efb169dbd9259ca_s=3000x_.jpg);
    }
  
    `}>
        </Column>


        <Column className="--left pb--50 pt--60" name={"fotogalerie"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 el--2" anim={"2"} animS={"5"} style={{"maxWidth":""}} columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"opravy převodovek"} src={"https://cdn.swbpg.com/t/i/template/124/img-1_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/i/template/124/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/124/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/124/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/124/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/124/img-1_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Repase průmyslové převodovky"} src={"https://cdn.swbpg.com/t/17189/8392173b6a214f70bb81184c1a773705_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/17189/8392173b6a214f70bb81184c1a773705_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17189/8392173b6a214f70bb81184c1a773705_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17189/8392173b6a214f70bb81184c1a773705_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17189/8392173b6a214f70bb81184c1a773705_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 el--2" anim={"2"} animS={"5"} style={{"maxWidth":""}} columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"převodovka"} src={"https://cdn.swbpg.com/t/17189/94b832f5bf50467cbf674c3201152899_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/17189/94b832f5bf50467cbf674c3201152899_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17189/94b832f5bf50467cbf674c3201152899_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17189/94b832f5bf50467cbf674c3201152899_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17189/94b832f5bf50467cbf674c3201152899_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/17189/94b832f5bf50467cbf674c3201152899_s=2000x_.jpg 2000w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Oprava průmyslové převodovky"} src={"https://cdn.swbpg.com/t/17189/ffdde2f69133492e9e0e1d3d59350019_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/17189/ffdde2f69133492e9e0e1d3d59350019_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17189/ffdde2f69133492e9e0e1d3d59350019_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17189/ffdde2f69133492e9e0e1d3d59350019_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17189/ffdde2f69133492e9e0e1d3d59350019_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/17189/ffdde2f69133492e9e0e1d3d59350019_s=2000x_.jpg 2000w"} position={{"x":"0%","y":"-2.5041736227045073%"}}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"5mclrnhk3uv"} layout={"l8"} lightbox={false}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Průmyslová převodovka"} src={"https://cdn.swbpg.com/t/17189/ea9382a30af54a67a8dd8e263a424fb1_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17189/ea9382a30af54a67a8dd8e263a424fb1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17189/ea9382a30af54a67a8dd8e263a424fb1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17189/ea9382a30af54a67a8dd8e263a424fb1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17189/ea9382a30af54a67a8dd8e263a424fb1_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Průmyslová převodovka"} src={"https://cdn.swbpg.com/t/17189/95296fbd280d457e931109c19a09707b_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17189/95296fbd280d457e931109c19a09707b_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17189/95296fbd280d457e931109c19a09707b_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17189/95296fbd280d457e931109c19a09707b_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17189/95296fbd280d457e931109c19a09707b_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Průmyslová převodovka"} src={"https://cdn.swbpg.com/t/17189/d9977c98fd7b41448673cdeb1e7d3477_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17189/d9977c98fd7b41448673cdeb1e7d3477_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17189/d9977c98fd7b41448673cdeb1e7d3477_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17189/d9977c98fd7b41448673cdeb1e7d3477_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17189/d9977c98fd7b41448673cdeb1e7d3477_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Průmyslová převodovka"} src={"https://cdn.swbpg.com/t/17189/789ef143dfed49d7b1f238e3def4738c_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17189/789ef143dfed49d7b1f238e3def4738c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17189/789ef143dfed49d7b1f238e3def4738c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17189/789ef143dfed49d7b1f238e3def4738c_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17189/789ef143dfed49d7b1f238e3def4738c_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Průmyslová převodovka"} src={"https://cdn.swbpg.com/t/17189/219fea5fd9e444b5b449617fcfc8b9bf_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17189/219fea5fd9e444b5b449617fcfc8b9bf_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17189/219fea5fd9e444b5b449617fcfc8b9bf_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17189/219fea5fd9e444b5b449617fcfc8b9bf_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17189/219fea5fd9e444b5b449617fcfc8b9bf_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Průmyslová převodovka"} src={"https://cdn.swbpg.com/t/17189/f226c05fffa24817a430dc191b94a360_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17189/f226c05fffa24817a430dc191b94a360_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17189/f226c05fffa24817a430dc191b94a360_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17189/f226c05fffa24817a430dc191b94a360_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17189/f226c05fffa24817a430dc191b94a360_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/17189/f226c05fffa24817a430dc191b94a360_s=2000x_.jpg 2000w"} position={{"x":"-53.6266318537859%","y":"-17.48302872062663%"}}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Image alt={"Servis převodovek"} src={"https://cdn.swbpg.com/t/17189/ad892741d5704fc686c7df8c22a8858d_s=860x_.jpg"} svg={false} ratio={null} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17189/ad892741d5704fc686c7df8c22a8858d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17189/ad892741d5704fc686c7df8c22a8858d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17189/ad892741d5704fc686c7df8c22a8858d_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17189/ad892741d5704fc686c7df8c22a8858d_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/17189/ad892741d5704fc686c7df8c22a8858d_s=2000x_.jpg 2000w"} position={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"2o7hecmazhi"}>
          
          <ColumnWrap className="column__flex --left el--2" columns={"2"} fullscreen={false}>
            
            <ColumnWrapper style={{"maxWidth":436}}>
              
              <Image src={"https://cdn.swbpg.com/t/17189/257893d0f1b34dd8a524f3f0f9f3e764_s=860x_.jpg"} svg={false} ratio={"9:16"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":438}} srcSet={"https://cdn.swbpg.com/t/17189/257893d0f1b34dd8a524f3f0f9f3e764_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17189/257893d0f1b34dd8a524f3f0f9f3e764_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17189/257893d0f1b34dd8a524f3f0f9f3e764_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17189/257893d0f1b34dd8a524f3f0f9f3e764_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/17189/257893d0f1b34dd8a524f3f0f9f3e764_s=2000x_.jpg 2000w"} position={null}>
              </Image>

              <Text className="text-box" style={{"maxWidth":467}} content={"<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17189/7b06f931631a44a8a57a653e2a3c77db_s=860x_.jpg"} svg={false} ratio={"4:3"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/17189/7b06f931631a44a8a57a653e2a3c77db_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17189/7b06f931631a44a8a57a653e2a3c77db_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17189/7b06f931631a44a8a57a653e2a3c77db_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17189/7b06f931631a44a8a57a653e2a3c77db_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/17189/7b06f931631a44a8a57a653e2a3c77db_s=2000x_.jpg 2000w"} position={null}>
              </Image>

              <Title className="title-box fs--26" content={"Laserové ustavování spojek do osy <br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"o378hx5dmag"} layout={"l4"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/17189/b39dd3d252ee47af9b5355a9387bb4fc_s=860x_.jpg"} svg={false} use={"url"} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/17189/b39dd3d252ee47af9b5355a9387bb4fc_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17189/b39dd3d252ee47af9b5355a9387bb4fc_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17189/b39dd3d252ee47af9b5355a9387bb4fc_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17189/b39dd3d252ee47af9b5355a9387bb4fc_s=1400x_.jpg 1400w"} target={null} lightbox={false}>
              </Image>

              <Text className="text-box" content={"<br>"}>
              </Text>

              <Button className="btn-box" use={"file"} href={"https://cdn.swbpg.com/d/17189/galerie.pdf"} target={null} content={"Ostatní galerie"} document={"3bb2e82eb1d044dbaced41742ed76bb7"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/17189/219fea5fd9e444b5b449617fcfc8b9bf_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/17189/219fea5fd9e444b5b449617fcfc8b9bf_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17189/219fea5fd9e444b5b449617fcfc8b9bf_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17189/219fea5fd9e444b5b449617fcfc8b9bf_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17189/219fea5fd9e444b5b449617fcfc8b9bf_s=1400x_.jpg 1400w"}>
              </Image>

              <Text className="text-box text-box--center fs--18 w--300 swpf--uppercase ls--04" content={"<b>Výrobní možnosti f. Gear technik<br></b>"}>
              </Text>

              <Button className="btn-box" href={"https://cdn.swbpg.com/d/17189/scn-0016.pdf"} content={"Tabulka"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--30" name={"kontakt"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--1 pb--30 pt--30" anim={"2"} animS={"5"} style={{"maxWidth":1080}} columns={"1"}>
            
            <ColumnWrapper className="--center" style={{"maxWidth":900}}>
              
              <Title className="title-box" style={{"maxWidth":700}} content={"Gear technik s.r.o.<br>Lešovice 13, 331 62 Nečtiny,<br>provozovna Manětín 347<br>Tel. 722 903 027, Lodr Pavel<br>info@gear-technik.cz<br>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":""}} content={"Kancelář firmy Lešovice 13, 331 62 Nečtiny, provozovna (dílna), bývalá kovárna, Manětín 347<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}